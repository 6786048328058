@import '../../node_modules/antd/lib/style/themes/default.less';

:root {
  --theme-color: #4eb45e;
  --theme-light-color: #e1fae5;
}

// ColorPicker Variables
@white-color: #ffffff;
@black-color: #000000;

//global style variables
@primary-color: #003365;
@secondary-color: #fa8c15;
@text-color: #545454;
@text-color-secondary: #595959;
@heading-color: #535353;
@header-text-color: #262626;
@layout-header-background: #fefefe;
@layout-header-background-dark: #003365;
@layout-footer-background: #fffffe;
@body-background: #f5f5f5;
@nav-bar-bg: #fffffc;
@nav-bar-text-color: @text-color;

@nav-dark-bg: #003366;
@nav-dark-text-color: #038fdd;
@menu-dark-bg: @nav-dark-bg;
@menu-dark-color: @nav-dark-text-color;
@menu-dark-highlight-color: @secondary-color;
@menu-dark-arrow-color: @nav-dark-text-color;
@hor-nav-text-color: #fffffd;

@border-radius-base: 6px;
@border-radius-sm: 4px;
@border-radius-lg: 10px;

@theme-color: var(--theme-color);
@trhover: #f5fbf6;
@trselected: #ddfce2;
@themeLightColor: var(--theme-light-color);
@themeDarkBoreder: #369945;
@tableBackGroundColor: #f2fcf4;
@inputLightColor: #f3fcf5;
@leftOptionLightColor: #bddec2;
@themeLightBorder: #beeec5;
@lightCardBg: #f2fff4;
@tableborderColor: #f2fcf4;
@table-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
@header-strip-color: linear-gradient(to right, #ddfce2 50%, @theme-color 50%) !important;
@inputDarkBg: #bddec2;
@thank-bg: linear-gradient(to bottom, #fff 0%, #f1f9f2 100%);
@light-theme-border: #beefc6;
@redColor: #db1c1c;
@blueColor: #296cb1;
@greenColor: #469c1a;
@yellowColor: #d8c803;
@headHover: #d8f0dc;
@textDark: #9de1a8;

@enquiryColor: #6bc950;
@enquiryLightColor: #d7f0d0;
@salesColor: #ffc600;
@salesLightColor: #ffeeb2;
@kycColor: #c95050;
@kycLightColor: #efcccc;

body {
  font-family: 'gilroy';
  font-weight: 400;
  font-size: 14px;
}

.smallScroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: #fff !important;
}

.grayScroll::-webkit-scrollbar-thumb {
  background-color: #ddd !important;
}

::-webkit-scrollbar // .pagination-height .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body::-webkit-scrollbar

  {
  width: 10px;
  height: 10px;
  /* This is more usable for users trying to click it. */
  background-color: #ddd !important;
  -webkit-border-radius: 100px;
}

::-webkit-scrollbar-thumb // .pagination-height .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body::-webkit-scrollbar-thumb

  {
  background-color: #2f3589 !important;
}

/* hover effect for both scrollbar area, and scrollbar 'thumb' */
::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.09);
}

/* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
::-webkit-scrollbar-thumb:vertical {
  /* This is the EXACT color of Mac OS scrollbars.
     Yes, I pulled out digital color meter */
  // background: rgba(0,0,0,0.5);
  -webkit-border-radius: 100px;
}

::-webkit-scrollbar-thumb:vertical:active {
  background: rgba(0, 0, 0, 0.61);
  /* Some darker color when you click it */
  -webkit-border-radius: 100px;
}

::-webkit-scrollbar-thumb {
  background-color: @theme-color !important;
}

.scrollbar-container.horizontal {
  height: 4px !important;
}

.scrollbar-container.horizontal .scrollbar {
  height: 4px !important;
}
@import 'variables.less';

.notesWrapper {
  .notesGrouping {
    margin-bottom: 20px;
    .notesType {
      color: #fff;
      border-radius: 4px 4px 0 0;
      background: @theme-color;
      padding: 2px 5px;
      font-size: 12px;
      display: inline-block;
    }
    .notesItem {
      .customcollapselist {
        box-shadow: @table-box-shadow;
        background-color: #fff;
        border: 1px solid @tableBackGroundColor;
        padding: 8px;
        position: relative;
        .accordion__button {
          background-color: transparent;
          padding: 0;
          display: flex;
          align-items: center;
          &:focus {
            outline: none;
          }
          &:before {
            height: 8px;
            width: 8px;
          }
        }
        .noteAction {
          position: absolute;
          right: 10px;
          top: 10px;
          display: flex;
          align-items: center;
          img {
            width: 16px;
            height: 16px;
            margin-right: 5px;
          }
          span {
            color: #878383;
          }
        }
        h2 {
          margin: 0;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }
}

.notesClose {
  .notesItem {
    .customcollapselist {
      .accordion__button {
        text-decoration: line-through;
      }
    }
    .notesDesc {
      p {
        text-decoration: line-through;
      }
    }
  }
}

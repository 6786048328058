@import 'variables.less';

.datepicker {
  border-bottom: 2px solid;
  width: 100%;
}
.soltBookLeft1 {
  white-space: nowrap;
  width: 100% !important;
}
.OfficeViewPopup {
  .ant-drawer-content-wrapper {
    max-width: 95% !important;
    width: 1612px !important;
    min-width: 84% !important;
  }
}
.officeBookWrapper {
  display: flex;
  height: 60%;
  width: 100%;

  .officeBookLeft {
    width: 100%;
    background-color: @themeLightColor;
    height: 100%;
    padding: 15px;
    .officeDateListing {
      .officeTopLeft {
        margin-bottom: 20px;
      }
      .cardListinOffice {
        .cardListOfficeItem {
          background-color: @lightCardBg;
          border-radius: 8px;
          position: relative;
          margin-bottom: 8px !important;
          cursor: pointer;
          .cardListOfficeTop {
            padding: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: none !important;
          }
          &:after {
            content: '';
            width: 4px;
            height: 16px;
            background: transparent;
            position: absolute;
            border-radius: 0 10px 10px 0;
            margin: auto;
            left: 0;
            top: 0;
            bottom: 0;
            background: transparent;
            border-radius: 0 10px 10px 0;
          }
          &.active {
            background-color: #fff;
            box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.23);
            &:after {
              background: @theme-color;
            }
          }
        }
      }
    }
  }
  .officeBookRight {
    width: calc(~'100% - 340px');
    height: 100%;
    // overflow: auto;
    // padding: 15px;
    .appoinmentOfficeRightTop {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      padding: 15px 15px 0 15px;
      .appointmentDate {
        margin-left: 10px;
      }
    }
    .soltWrapper {
      display: flex;
      .soltBookLeft {
        // width: calc(~"100% - 300px");
        width: 100%;
        position: relative;
        height: 100vh;
      }

      .soltBookTop {
        height: 60%;
      }
      .soltBookBottom {
        height: calc(~'40% - 56px');
        background-color: #fff;
        padding: 15px 15px 0 15px;
        overflow: auto;
        .from-group {
          width: 50%;
          padding-right: 10px;
        }
      }
      .officeBookSolt {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        overflow: auto;
        padding: 0 15px 15px 15px;
        .officeBookSoltItem {
          // width: calc(~"306px - 20px");
          width: calc(~'450px - 20px');
          margin: 10px;
          border: 2px dotted #e1e1e1;
          // padding: 10px;
          border-radius: 10px;
          background: #fff;
          .officeBookSoltTitle {
            font-size: 14px;
            padding: 10px;
            border-bottom: 1px dashed #e1e1e1;
            font-weight: 600;
          }
          .officeBookSoltInner {
            display: flex;
            flex-wrap: wrap;
            padding: 10px;
            .officeBookSoltTime {
              cursor: pointer;
              background: @themeLightColor;
              color: @theme-color;
              padding: 3px 8px;
              border-radius: 10px;
              font-size: 12px;
              margin: 4px;
              min-width: 123px;
              text-align: center;
              &.soltBook {
                background: rgba(84, 168, 32, 19%);
                color: #54a820;
              }
              &.allReadyBook {
                opacity: 0.5;
                pointer-events: none;
              }
            }
          }
        }
      }
      .selectVirtualAction {
        position: absolute;
        bottom: 0;
        box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.09);
        background: #fff;
        width: 100%;
        left: 0;
        display: flex;
        justify-content: space-between;
        padding: 10px 5px;
        align-items: center;
        .formSelect {
          margin-bottom: 0;
          width: 80%;
          label {
            display: none;
          }
          .ant-select {
            border: 1px solid #e4e4e4;
            border-radius: 5px;
            padding: 8px 10px;
            .ant-select-selection-selected-value {
              font-size: 14px;
            }
          }
        }
        button {
          background-color: @theme-color;
          color: #fff;
          border: none;
          padding: 10px 6px;
          font-size: 13px;
          border-radius: 5px;
          width: 18%;
        }
      }
      .yourAppointmentSolt {
        width: 300px;
        background-color: #fff;
        padding: 10px 0;
        .yourSoltTop {
          margin-bottom: 20px;
          padding: 0 10px;
        }
        .yourSoltList {
          height: calc(~'100vh - 66px');
          overflow: auto;
          padding: 0 10px;
          .yourSoltItem {
            background: #fff;
            padding: 0;
            border-radius: 10px;
            margin-bottom: 10px;
            border: 1px solid #e8e8e8;
            .yourSoltItemTop {
              display: flex;
              align-items: center;
              justify-content: space-between;
              border-bottom: 1px solid #e8e8e8;
              padding: 5px 10px;
              border-radius: 10px 10px 0 0;
              .yourSoltTime {
                font-weight: 600;
                // background: #eceff9;
                color: #000;
              }
              .yourSoltDetailCenter {
                background: #e8e8e8;
                padding: 5px;
                border-radius: 3px;
                font-size: 12px;
                font-weight: 600;
                letter-spacing: 0.2px;
              }
            }
            .yourSoltDetail {
              padding: 10px;
              display: flex;
              align-items: center;
              text-align: center;
              justify-content: space-between;
              .yourSoltDetailleft {
              }
              .yourSoltDetailRight {
                display: flex;
                align-items: center;
                img {
                  margin-left: 5px;
                  width: 16px;
                  height: 16px;
                }
              }
              .appointmentBookBtn {
                margin: auto;
                background: #fff;
                border: none;
                color: @theme-color;
                border-radius: 10px;
                padding: 4px 15px;
                border: 1px solid @theme-color;
              }
            }
          }
        }
      }
    }
  }
}

.OfficeBookingWrapper {
  padding: 15px;
  width: 100%;
  background-color: #fff;
  .officeBookStatic {
    width: 100%;
    margin-bottom: 20px;
    background: #fffcf9;
    border: 1px dashed #fce4cc;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    .officeBookSelect {
      margin-right: 10px;
      span {
        font-size: 14px;
        &:first-child {
          margin-right: 5px;
          color: #555;
          text-transform: uppercase;
          font-size: 12px;
        }
        &:last-child {
          font-weight: 600;
        }
      }
    }
  }
  .officeBookingArea {
    display: flex;
    height: calc(~'100% - 47px');
    .officeBookingLeft {
      width: 50%;
      padding-right: 25px;
      .customCalender {
        border: none;
        width: calc(~'100% - 40%');
        padding-right: 25px;
        .react-calendar__navigation button:enabled:hover,
        .react-calendar__navigation button:enabled:focus {
          background-color: transparent;
        }
        .react-calendar__tile {
          padding: 2px;
          abbr {
            border-radius: 10px;
            background-color: @themeLightColor;
            display: block;
            padding: 10px;
            // width: 50px;
            // height: 50px;
            // margin: 2px 0;
          }
          &.react-calendar__tile--active {
            background-color: transparent;
            abbr {
              background-color: @theme-color;
              color: #fff;
            }
          }
          &:hover {
            background-color: transparent;
          }
        }
      }
      .bookappointmentTimeDate {
        display: flex;
        margin-top: 20px;
        .bookAppointmentTime {
          width: 40%;
          overflow: auto;
          height: calc(~'100vh - 210px');
          .bookAppointmentTimeItem {
            margin: 5px;
            .timeSelect {
              border: 1px solid @theme-color;
              padding: 15px 25px;
              border-radius: 10px;
              color: @theme-color;
              font-size: 16px;
              text-align: center;
              cursor: pointer;
              font-weight: 600;
            }
            .bookAppointTimeConfirm {
              // display: none;
            }
          }
          .bookAppointTimeConfirm {
            display: flex;
            justify-content: space-between;
            span {
              border: 1px solid @theme-color;
              padding: 15px 15px;
              border-radius: 10px;
              color: @theme-color;
              font-size: 16px;
              text-align: center;
              cursor: pointer;
              font-weight: 600;
              width: 49%;
              &:first-child {
                background-color: @theme-color;
                color: #fff;
                border: 1px solid @theme-color;
              }
            }
          }
        }
      }
    }

    .officeBookingRight {
      width: 100%;
      // border-left: 1px solid #e4e4e4;
      // padding-left: 25px;
      .from-group {
        width: 100%;
        padding-right: 0;
      }
      .bookAppointButton {
        button {
          margin-right: 10px;
        }
      }
      &.halfForm {
        width: 50%;
        border-left: 1px solid #e4e4e4;
        padding-left: 25px;
      }
    }
  }
}

.officeBookRight1 {
  width: calc(~'100% - 340px');
  height: 100%;
  // overflow: auto;
  // padding: 15px;
  .appoinmentOfficeRightTop {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 15px 15px 0 15px;
    .appointmentDate {
      margin-left: 10px;
    }
  }
  .soltWrapper {
    display: flex;
    .soltBookLeft {
      // width: calc(~"100% - 300px");
      width: 100%;
      position: relative;
      height: 100vh;
    }

    .soltBookTop {
      height: 60%;
    }
    .soltBookBottom {
      height: calc(~'40% - 56px');
      background-color: #fff;
      padding: 15px 15px 0 15px;
      overflow: auto;
      .from-group {
        width: 50%;
        padding-right: 10px;
      }
    }
    .officeBookSolt {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      height: 100%;
      overflow: auto;
      padding: 0 15px 15px 15px;
      .officeBookSoltItem {
        // width: calc(~"306px - 20px");
        width: calc(~'450px - 20px');
        margin: 10px;
        border: 2px dotted #e1e1e1;
        // padding: 10px;
        border-radius: 10px;
        background: #fff;
        .officeBookSoltTitle {
          font-size: 14px;
          padding: 10px;
          border-bottom: 1px dashed #e1e1e1;
          font-weight: 600;
        }
        .officeBookSoltInner {
          display: flex;
          flex-wrap: wrap;
          padding: 10px;
          .officeBookSoltTime {
            cursor: pointer;
            background: @themeLightColor;
            color: @theme-color;
            padding: 3px 8px;
            border-radius: 10px;
            font-size: 12px;
            margin: 4px;
            min-width: 123px;
            text-align: center;
            &.soltBook {
              background: rgba(84, 168, 32, 19%);
              color: #54a820;
            }
            &.allReadyBook {
              opacity: 0.5;
              pointer-events: none;
            }
          }
        }
      }
    }
    .selectVirtualAction {
      position: absolute;
      bottom: 0;
      box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.09);
      background: #fff;
      width: 100%;
      left: 0;
      display: flex;
      justify-content: space-between;
      padding: 10px 5px;
      align-items: center;
      .formSelect {
        margin-bottom: 0;
        width: 80%;
        label {
          display: none;
        }
        .ant-select {
          border: 1px solid #e4e4e4;
          border-radius: 5px;
          padding: 8px 10px;
          .ant-select-selection-selected-value {
            font-size: 14px;
          }
        }
      }
      button {
        background-color: @theme-color;
        color: #fff;
        border: none;
        padding: 10px 6px;
        font-size: 13px;
        border-radius: 5px;
        width: 18%;
      }
    }
    .yourAppointmentSolt {
      width: 300px;
      background-color: #fff;
      padding: 10px 0;
      .yourSoltTop {
        margin-bottom: 20px;
        padding: 0 10px;
      }
      .yourSoltList {
        height: calc(~'100vh - 66px');
        overflow: auto;
        padding: 0 10px;
        .yourSoltItem {
          background: #fff;
          padding: 0;
          border-radius: 10px;
          margin-bottom: 10px;
          border: 1px solid #e8e8e8;
          .yourSoltItemTop {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #e8e8e8;
            padding: 5px 10px;
            border-radius: 10px 10px 0 0;
            .yourSoltTime {
              font-weight: 600;
              // background: #eceff9;
              color: #000;
            }
            .yourSoltDetailCenter {
              background: #e8e8e8;
              padding: 5px;
              border-radius: 3px;
              font-size: 12px;
              font-weight: 600;
              letter-spacing: 0.2px;
            }
          }
          .yourSoltDetail {
            padding: 10px;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: space-between;
            .yourSoltDetailleft {
            }
            .yourSoltDetailRight {
              display: flex;
              align-items: center;
              img {
                margin-left: 5px;
                width: 16px;
                height: 16px;
              }
            }
            .appointmentBookBtn {
              margin: auto;
              background: #fff;
              border: none;
              color: @theme-color;
              border-radius: 10px;
              padding: 4px 15px;
              border: 1px solid @theme-color;
            }
          }
        }
      }
    }
  }
}

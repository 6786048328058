@import 'variables.less';

.thankYouWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: @thank-bg;
  .thankYouMainBox {
    background: #fff;
    padding: 50px;
    border-radius: 20px;
  }
  .thankYouInnerBox {
    max-width: 700px;
    margin: auto;
    text-align: center;
    background: @header-strip-color !important;
    padding: 10px;
    border-radius: 20px;
    box-shadow: 1px 11px 20px 0px rgba(0, 0, 0, 0.15);
    .thankYouImgBlock {
      margin-bottom: 50px;
      img {
        width: 150px;
        height: 150px;
      }
    }
    .thankYouPageContent {
      h2 {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 20px;
      }
      p {
        color: #454545;
        line-height: 24px;
        margin-bottom: 20px !important;
      }
      button {
        padding: 13px 24px;
      }
    }
  }
}

@primary-color: #283895;@secondary-color: #9999ff;@text-color: #545454;@heading-color: #191c27;@nav-dark-bg: #283895;@nav-dark-text-color: #9DDAE9;@header-text-color: #191c27;@layout-header-background: #1b1010;@layout-footer-background: #fffffd;@body-background: #fafafa;@hor-nav-text-color: #fffffd;
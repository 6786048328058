@import '../../styles/variables.less';

.customCheckBox {
  position: relative;

  &:focus-within {
    outline: 2px solid @theme-color;
  }

  label {
    margin-left: 5px;
    &:focus-within {
      outline: 2px solid @black-color;
    }
  }

  input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }

  .customCheckBoxLebel {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    border-collapse: separate;
    transition: all 0.3s;

    &:after {
      position: absolute;
      top: 50%;
      left: 21%;
      display: table;
      width: 5.71428571px;
      height: 9.14285714px;
      border: 2px solid #fff;
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg) scale(0) translate(-50%, -50%);
      opacity: 0;
      transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
      content: ' ';
    }
  }

  input:checked ~ .customCheckBoxLebel {
    background-color: @theme-color;
    border: 1px solid @theme-color;

    &:after {
      width: 5px;
      height: 8px;
      transform: rotate(45deg) scale(1) translate(-50%, -50%);
      opacity: 1;
    }
  }

  input:indeterminate ~ .customCheckBoxLebel {
    background-color: @theme-color;
    border: 1px solid @theme-color;

    &:after {
      width: 8px;
      height: 8px;
      border-right: 0;
      top: 25%;
      left: 50%;
      transform: rotate(0) scale(1) translate(-50%, -50%);
      opacity: 1;
    }
  }
}

@primary-color: #283895;@secondary-color: #9999ff;@text-color: #545454;@heading-color: #191c27;@nav-dark-bg: #283895;@nav-dark-text-color: #9DDAE9;@header-text-color: #191c27;@layout-header-background: #1b1010;@layout-footer-background: #fffffd;@body-background: #fafafa;@hor-nav-text-color: #fffffd;
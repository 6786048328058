@import "variables.less";

.editProfilePageInner{
    margin-top: 40px;
    display: flex;
    .profileLeft{
        width: 140px;
        margin: 0 40px 0 0;
        .userProfileRound{
            width: 140px;
            height: 140px;
            border-radius: 100%;
            font-size: 50px;
            background: rgb(64, 188, 134);
            display: block;
            color: #fff;
            text-align: center;
            cursor: pointer;
            line-height: 140px;
        }
    }
    .profileRight{
        width: calc(~"100% - 180px");
        .profielTwofactor{
            display: flex;
            align-items: center;
            border-bottom: 1px solid #e4e4e4;
            padding-bottom: 10px;
            width: 100%;
            .profileTwoFactorBlock{
                margin-left: 15px;
                h2{
                    font-size: 18px;
                    font-weight: bold;
                    margin-bottom: 0;
                    margin-right: 10px;
                }
                p{
                    font-size: 14px;
                    margin-bottom: 0;
                }
            }
        }
    }   
}
.colorDropdownWrapper{
    padding: 0 10px 10px !important;
    width: 260px;
    box-shadow: 0 1px 10px 0 rgba(0,0,0,.25);
    background: #fff;
    border-radius: 6px;
    .profilecolorDropdown{
        .colorTopWrapper{
            display: flex;
            flex-wrap: wrap;
            .colorItem{
                width: 24px;
                height: 24px;
                margin: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100%;
                cursor: pointer;
                transform: scale(0.8);
                transition: all 0.5s ease-in-out;
                &.selected{
                    transform: scale(1);
                }
                &:hover{
                    transform: scale(1);
                }
                img{
                    width: 14px;
                    height: 14px;
                }
                &.color1{
                    border: 1px dashed #dbdbdb;
                    background-color: #fff;
                }
                &.color2{
                    background-color: rgb(64, 188, 134);;
                }
                &.color3{
                    background-color: rgb(26, 188, 156);
                }
                &.color4{
                    background-color: rgb(39, 174, 96);
                }
                &.color5{
                    background-color: rgb(0, 215, 23);
                }
                &.color6{
                    background-color: rgb(243, 29, 47);
                }
                &.color7{
                    background-color: rgb(236, 85, 92);
                }
                &.color8{
                    background-color: rgb(252, 87, 94);
                }
                &.color9{
                    background-color: rgb(252, 180, 16);
                }
                &.color10{
                    background-color: rgb(177, 126, 34);
                }
                &.color11{
                    background-color: rgb(242, 77, 22);
                }
                &.color12{
                    background-color: rgb(255, 134, 0);
                }
                &.color13{
                    background-color: rgb(236, 102, 37);
                }
                &.color14{
                    background-color: rgb(41, 128, 185);
                }
                &.color15{
                    background-color: rgb(52, 152, 219);
                }
                &.color16{
                    background-color: rgb(82, 140, 203);
                }
                &.color17{
                    background-color: rgb(9, 24, 236);
                }
                &.color18{
                    background-color: rgb(25, 158, 199);
                }
                &.color19{
                    background-color: rgb(3, 162, 253);
                }
                &.color20{
                    background-color: rgb(123, 104, 238);
                }
                &.color21{
                    background-color: rgb(7, 67, 84);
                }
                &.color22{
                    background-color: rgb(7, 67, 84);
                }
                &.color23{
                    background-color: rgb(52, 73, 94);
                }
                &.color24{
                    background-color: rgb(24, 29, 33);
                }

            }
        }
        .commonButton{
            margin-top: 10px;
            width: 100%;
            padding: 10px 5px;
        }
    }
}
.actionButonBottom{
    margin-top: 15px;
}
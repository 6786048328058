@import 'variables.less';

.savedSearchMainWrapper {
  background: @tableBackGroundColor;
  // padding: 0 10px 0 10px;
  .addFormIcon {
    right: 24px;
    bottom: 68px;
  }
  .savedSearchWrapper {
    height: ~'calc(100vh - 205px)';
    overflow-y: auto;
    position: relative;
    // padding-right: 10px;
    // margin-top: 20px;
    // margin-right: -10px;
  }
}
.savedSearchItem {
  width: 100%;
  border-radius: 10px;
  background-color: #fff !important;
  box-shadow: 0 0 9px 0px rgba(0, 0, 0, 9%);
  position: relative;
  margin-bottom: 15px;
  // &:after{
  //     content: "";
  //     width: 7px;
  //     height: 46px;
  //     background: #6e8fe7;
  //     position: absolute;
  //     border-radius: 0 10px 10px 0;
  //     margin: auto;
  //     left: 0;
  //     top: 0;
  //     bottom: 0;
  // }

  .separator {
    position: relative;
    margin-left: 10px;
    margin-right: 10px;

    &:after {
      background: #c9c9c9;
      bottom: 0;
      content: '';
      display: block;
      height: 13px;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0;
      width: 1px;
    }
  }

  .savedSearchTop {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e8e8e8 !important;
    padding: 10px 20px;
    .savedTitle {
      font-size: 16px;
      color: #000;
      font-weight: 600;
      .noOfDiamondTitle {
        font-size: 14px;
        font-weight: 400;
        margin-left: 5px;
      }
    }
    .customerName {
      display: flex;
      align-items: center;
      color: #7f7f7f;
      font-size: 14px;
      span {
        margin: 0 2.5px 0 2.5px;
      }
    }
  }
  .savedSearchAction {
    display: flex;
    .savedSearchActionItem {
      margin-left: 10px;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
  .savedSearchBottom {
    display: flex;
    flex-wrap: wrap;
    padding: 5px 20px;
    .savedSearchValue {
      display: inline-block;
      // margin: 5px 20px 5px 0;
      margin: 5px 0 5px 0;
      position: relative;
      flex-wrap: wrap;
      span {
        font-size: 14px;
        &.savedSearchLabel {
          margin-right: 5px;
          font-weight: bold;
          color: #606060;
        }
      }
      // &:after {
      //   content: '';
      //   background: #c9c9c9;
      //   width: 1px;
      //   height: 13px;
      //   position: absolute;
      //   right: -10px;
      //   display: block;
      //   top: 0;
      //   bottom: 0;
      //   margin: auto;
      // }
      &:last-child {
        &:after {
          content: none;
        }
      }
    }
  }
}

.tableinSavedSearch{
  .savedSearchItem{
    margin: 10px 0;
    box-shadow: 0 0 9px 0px rgba(0 ,0 ,0 , 0.05);
    .savedSearchBottom{
      padding: 10px 20px;
    }
    .savedSearchAction{
      position: absolute;
      right: 11px;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 20px;
    }
  }
}
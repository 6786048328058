@import "../../../styles/variables.less";

.similarStoneBlock{
    padding: 10px;
    height: 100%;
    overflow: auto;
    border-left:1px solid #ddd;
    // border-radius: 10px;
    width: 20%;
    background: #fff;
    .similarStoneWrapper{
        height: calc(100% - 50px);
    }
    .mainHeadingTitle {
        text-align: center;
    }
    .similarStoneItem{
        width: 100%;
        text-align: center;
        margin-bottom: 12px;
        border: 1px solid #ececec;
        padding: 10px;
        background: #f6f6f6;
        border-radius: 5px;
        img{
            width: 100px;
            height: 100px;
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
            display: block;
            margin:auto auto 10px auto;
        }
        h2{
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
        }
    }
}
@primary-color: #283895;@secondary-color: #9999ff;@text-color: #545454;@heading-color: #191c27;@nav-dark-bg: #283895;@nav-dark-text-color: #9DDAE9;@header-text-color: #191c27;@layout-header-background: #1b1010;@layout-footer-background: #fffffd;@body-background: #fafafa;@hor-nav-text-color: #fffffd;
@media (max-width: 767px) {
  .m-width-100 {
    width: 100% !important;
  }
}
.qrCodeOption {
  position: fixed;
  z-index: 1;
  right: 20px;
  bottom: 18px;
  width: 90px;
  padding: 12px 0 10px 0;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 0 14px 2px rgba(0, 0, 0, 0.19);
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  z-index: 10;
  img,
  canvas {
    width: 50px;
    height: 50px;
    // margin: auto auto 30px auto;
    margin: auto;
    object-fit: contain;
  }
  span {
    color: #000;
    text-transform: uppercase;
    font-size: 11px;
    text-align: center;
    display: block;
    margin-top: 5px;
    width: 100%;
    line-height: 16px;
    font-weight: 600;
    // position: absolute;
    // bottom: 5px;
  }
}
@media (max-width: 767px) {
  .qrCodeOption {
    display: none;
  }
}

@primary-color: #283895;@secondary-color: #9999ff;@text-color: #545454;@heading-color: #191c27;@nav-dark-bg: #283895;@nav-dark-text-color: #9DDAE9;@header-text-color: #191c27;@layout-header-background: #1b1010;@layout-footer-background: #fffffd;@body-background: #fafafa;@hor-nav-text-color: #fffffd;
@import "../../styles/variables.less";

.mainHeadingTitle{
    display: block;
    font-size: 22px;
    margin-right: 10px;
    color: #424242;
    margin-bottom: 0;
    text-transform: capitalize;
    font-weight: 600;
    letter-spacing: 1px;
}
.smallTitle{
    font-size: 18px;
}
@primary-color: #283895;@secondary-color: #9999ff;@text-color: #545454;@heading-color: #191c27;@nav-dark-bg: #283895;@nav-dark-text-color: #9DDAE9;@header-text-color: #191c27;@layout-header-background: #1b1010;@layout-footer-background: #fffffd;@body-background: #fafafa;@hor-nav-text-color: #fffffd;
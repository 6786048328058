@import "../../styles/variables.less";

.statusBlock{
    padding: 5px 13px;
    background: #ecfdf3;
    border-radius: 8px;
    color: #429945;
    font-size: 12px;
    text-transform: uppercase;
    display: inline-block;
    &.InActive{
        background: #fdecec;
        color: #d8404e;
    }
    &.active{
        background: #ecfdf3;
        color: #429945;
    }
}
@primary-color: #283895;@secondary-color: #9999ff;@text-color: #545454;@heading-color: #191c27;@nav-dark-bg: #283895;@nav-dark-text-color: #9DDAE9;@header-text-color: #191c27;@layout-header-background: #1b1010;@layout-footer-background: #fffffd;@body-background: #fafafa;@hor-nav-text-color: #fffffd;
@import '../../styles/variables.less';

.commonTextArea {
  border: none;
  border-bottom: 1px solid #e4e4e4;
  padding: 10px;
  width: 100%;
  margin-top: 5px;
  &:focus {
    outline: none;
  }
}

.error-message {
  .commonTextArea {
    border-bottom: 1px solid #ee3b3b !important;
  }
  .error {
    color: #ee3b3b;
    position: absolute;
    font-size: 10px;
    margin-top: 3px;
  }
}

.datePickerBlock {
  .ant-calendar-picker {
    input {
      padding: 0;
      border: none !important;
    }
  }
}

.ant-calendar-picker-container .ant-calendar .ant-calendar-input-wrap input {
  padding: 5px 0 !important;
}
.ant-calendar-picker-container .ant-calendar .ant-calendar-input-wrap {
  padding: 0 !important;
}
//comment for testing

.ant-calendar-picker-container.ant-calendar-picker-container-placment-bottomLeft{
  top:6px!important;
  left:132px!important;
  }

@primary-color: #283895;@secondary-color: #9999ff;@text-color: #545454;@heading-color: #191c27;@nav-dark-bg: #283895;@nav-dark-text-color: #9DDAE9;@header-text-color: #191c27;@layout-header-background: #1b1010;@layout-footer-background: #fffffd;@body-background: #fafafa;@hor-nav-text-color: #fffffd;
@import 'variables.less';

.diamondDetailPopup {
  .ant-drawer-mask {
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 1 !important;
  }
  .ant-drawer-header-no-title {
    .ant-drawer-close {
      background: #fff;
      right: auto;
      left: -46px;
      border-radius: 20px;
      top: 8px;
      width: 40px;
      height: 40px;
      line-height: 42px;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .ant-drawer-content-wrapper {
    width: 94% !important;
    padding: 0;
    height: 100%;
    .ant-drawer-body {
      width: 100%;
      height: 100%;
    }
  }
}
.diamondDetailWrapper {
  width: 100%;
  height: 100%;
  .diamondDetailTopTab {
    position: absolute;
    left: 0;
    top: 17px;
    border-bottom: none;
    z-index: 10000;
  }
  .diamondDetailInner {
    .diamondDetailMainTab {
      display: flex;
    }
    .diamondDetailRight {
      width: 55%;
      max-height: calc(~'100vh - 63px');
      position: relative;
      z-index: 1;
      display: flex;
      .diamondDetailMore {
        width: 80%;
        padding: 20px;
        overflow: auto;
        .detailSmallTitle {
          font-size: 18px;
          color: #424242;
          margin-bottom: 0;
          text-transform: uppercase;
          font-weight: 600;
          letter-spacing: 1px;
          margin-bottom: 20px;
        }
        .diamondDetailMoreHalf {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          ul {
            width: 49%;
            padding: 0;
            li {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              margin-bottom: 0;
              span {
                color: #a0a1a7;
                font-size: 14px;
                font-weight: normal;
                &:first-child {
                  width: 115px;
                  margin-right: 5px;
                  font-size: 12px;
                  letter-spacing: 0.5px;
                  text-transform: uppercase;
                }
                &:last-child {
                  font-size: 16px;
                  color: #424242;
                  word-break: break-word;
                  width: calc(~'100% - 120px');
                }
              }
            }
          }

          &.diamondFullBlock {
            ul {
              width: 100%;
              li {
                span {
                  &:first-child {
                    width: 190px;
                  }
                  &:last-child {
                    width: calc(~'100% - 195px');
                  }
                  &.addNotesOption {
                    display: flex;
                    align-items: center;
                    textarea {
                      width: calc(~'100% - 110px');
                      border: 1px solid #e2e2e2;
                      background-color: #fff;
                      height: 80px;
                      border-radius: 5px;
                      margin-right: 10px;
                      padding: 10px;
                      &:focus {
                        outline: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .diamondDetailLeft {
      width: 45%;
      position: relative;
      z-index: 1;
      height: calc(~'100vh - 63x');
      overflow-y: auto;
      overflow-x: hidden;
      .react-tabs {
        height: 100%;
      }
      .react-tabs__tab-panel {
        display: none;
        &.react-tabs__tab-panel--selected {
          display: block;
        }
      }
      .diamondDetailInnerDetail {
        height: calc(~'100% - 46px');
        .tab-content,
        .react-tabs__tab-panel,
        .react-tabs,
        .tab-pane {
          height: 100%;
        }
        .nodatFoundImage {
          text-align: center;
          img {
            margin-top: 20px;
            width: 150px !important;
            height: 150px !important;
          }
          span {
            display: block;
            margin-top: 10px;
            font-weight: 600;
            color: #000;
          }
        }
        .react-tabs__tab-panel {
          .diamondDetailImageBox {
            position: relative;
            height: 100%;
            padding: 20px 10px;
            text-align: center;
            &.htmlViewImage {
              height: calc(~'100vh - 129px');
            }
            .magnifier {
              width: 450px !important;
              height: 450px !important;
              margin: auto;
              overflow: hidden;
            }
            img:not([alt='loader']),
            iframe {
              width: 100%;
              height: 100%;
              margin: auto;
              object-fit: contain;
              border: none;
              &.certificateImage {
                height: auto;
              }
            }
            .imageBlock {
              max-width: 100%;
              height: 100% !important;
              img {
                width: 90%;
                height: 100%;
              }
            }
          }
          .smallSliderBlock {
            margin-top: 15px;
            padding: 0 55px;
            .slick-track {
              margin: auto;
            }
            .smallSliderImage {
              width: 60px !important;
              height: 60px;
              display: flex !important;
              margin: auto;
              align-items: center;
              justify-content: center;
              border: 1px solid #ddd;
              border-radius: 5px;
              cursor: pointer;
              img {
                width: 30px;
                height: 30px;
                object-fit: cover;
              }
            }
            &.smallSliderBlockimage {
              .smallSliderImage {
                // width: 80px !important;
                // height: 80px;
                img {
                  width: 35px;
                  height: 35px;
                }
              }
            }
            .slick-track {
              display: flex;
              justify-content: center;
              .slick-slide {
                width: 80px !important;
                height: 80px;
                margin: 0 0;
                &.slick-current {
                  .smallSliderImage {
                    border: 1px solid #000;
                  }
                }
              }
            }
            .slick-prev,
            .slick-next {
              width: 30px;
              height: 30px;
              background: rgba(0, 0, 0, 0.5);
              &:before {
                color: #fff;
              }
              &.slick-prev {
                left: 10px;
              }
              &.slick-next {
                right: 10px;
              }
            }
            .smallimgTitle {
              color: #454545;
              font-size: 12px;
              text-transform: uppercase;
              margin-top: 5px;
              display: block;
              text-align: center;
            }
          }
        }
      }
    }
    .diamondAllPrice {
      // border: 1px solid #e2e2e2;
      align-items: center;
      background: #f7f9ff;
      border: 1px dashed #cee3fe;
      padding: 10px;
      margin: 10px 10px 0 10px;
      // display: flex;
      /* box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.09); */
      position: relative;
      // background: #fff;
      border-radius: 10px;
      justify-content: space-between;
      .askingPriceBlock,
      .listPriceBlock,
      .m2mPriceBlock {
        display: flex;
        .priceBlockItem {
          display: flex;
          margin-bottom: 5px;
          margin-right: 10px;
          align-items: center;
          span {
            font-size: 14px;
            &:first-child {
              margin-right: 5px;
              color: #555;
              text-transform: uppercase;
              font-size: 12px;
            }
            &:last-child {
              font-weight: 600;
            }
          }
        }
        &.m2mPriceBlock {
          .priceBlockItem {
            margin-bottom: 0;
          }
        }
      }
    }
    .basicDetailFull {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      padding: 12px;
      margin-bottom: 10px;
      li {
        color: #212431;
        font-size: 20px;
        font-weight: 600;
        list-style: none;
        margin: 0 5px;
      }
    }
  }
}
.detailHalfWhite {
  width: 45%;
  height: 100%;
  position: absolute;
  left: 0;
  background-color: #fff;
  top: 0;
}
.detailHalfGray {
  width: 55%;
  height: 100%;
  position: absolute;
  right: 0;
  background-color: #f6f6f6;
  top: 0;
}

.diamondDetailClient {
  display: flex;
  padding-top: 10px;
  align-items: center;
  .diamondClientSerach {
    width: 35%;
  }
}
.diamondClientSerach {
  background: @inputLightColor;
  border: 1px solid #e3caaf;
  padding: 5px 5px 5px 15px;
  border-radius: 10px;
  margin-bottom: 0;
  // width:35%;
  width: 260px;
  margin-right: 10px;
  input {
    background-color: transparent;
    padding-left: 0;
    color: #000;
    border: none;
    &::placeholder {
      color: #000;
    }
    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }
  }
  .ant-input-suffix {
    svg {
      fill: @theme-color;
      width: 20px;
      height: 20px;
    }
  }
}
.newDiscount.red span {
  background: white;
  color: #f5222dd4;
  text-align: center;
}
.newDiscount.green span {
  background: white;
  color: #429945;
  text-align: center;
}

.diamondClientDetail {
  border: 1px solid #e2e2e2;
  width: calc(100% - 35%);
  padding: 12px 12px;
  border-radius: 10px;
  background: #f6f6f6;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  align-items: center;
  .diamondClientRightBorder {
    border-right: none !important;
    // padding-right: 5px;
    // margin-right: 5px;
  }
  .separeter {
    background: #e2e2e2;
    width: 1px;
    height: 15px;
  }
}
.diamondDetailSummary {
  width: 100%;
  .botoomStickyBar {
    display: none !important;
  }
}

.analyticsWrapper {
  padding: 10px;
  width: 100%;
  p.offerHistroyDec {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 0;
    width: 200px;
  }
  .offerHistroy,
  .similarStoneSale {
    border: 1px solid #e3caaf;
    border-radius: 10px;
    margin-top: 20px;
    .analtaticsTitle {
      padding: 10px;
      width: 100%;
      display: block;
      font-size: 22px;
      margin-right: 10px;
      color: #424242;
      margin-bottom: 0;
      text-transform: capitalize;
      font-weight: 600;
      letter-spacing: 1px;
      background: @tableBackGroundColor;
      border-radius: 10px 10px 0 0;
    }
    .searchResultTable {
      height: 300px;
      &::-webkit-scrollbar-thumb {
        background-color: #ddd !important;
      }
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        background: #fff !important;
      }
    }
  }
}
.cardBlockItem {
  width: 200px;
  border-radius: 15px;
  padding: 15px;
  background-color: #f2a751;
  margin-right: 10px;
  &.cardView2 {
    background-color: #eeac9d;
  }
  &.cardView3 {
    background-color: #2d2e46;
  }
  &.cardView4 {
    background-color: @theme-color;
  }
  .cardBlockTitle {
    color: #fff;
    font-size: 16px;
    letter-spacing: 0.5px;
    margin-bottom: 15px;
  }
  .cardBlockCount {
    font-size: 48px;
    color: #fff;
    line-height: 50px;
  }
}

.diamondImagePopup {
  .diamondDetailImageBox {
    position: relative;
    img:not([alt='loader']) {
      width: 100%;
      height: 80vh;
      object-fit: contain;
    }
  }
  .smallSliderImage {
    width: 60px !important;
    height: 60px;
    cursor: pointer;
    display: flex !important;
    margin: auto;
    border: 1px solid #ddd;
    border-radius: 5px;
    img {
      width: 35px;
      height: 35px;
      margin: auto;
      border-radius: 8px;
    }
  }
  .smallSliderBlockimage {
    .slick-current {
      .smallSliderImage {
        border: 1px solid #000;
      }
    }
    .slick-slide {
      width: 80px !important;
      height: 80px;
      &.slick-cloned {
        display: none;
      }
    }
  }
  .slick-list {
    .slick-track {
      display: flex;
      justify-content: center;
      margin: 10px auto auto auto;
    }
  }
}
.ant-drawer.ant-drawer-right.ant-drawer-open.diamondDetailPopup {
  z-index: 10000;
}

@import '../../styles/variables.less';

.customerMaintab {
  display: flex;
  padding: 0 15px !important;
  margin: 0;
  // overflow: hidden;
  border-bottom: 1px solid #efefef;
  position: relative;
  .scrollarea {
    width: 300px;
    .scrollarea-content {
      display: flex;
    }
  }
}

.customerMaintab li {
  list-style: none;
  padding: 15px 8px;
  position: relative;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1.5px;
  cursor: pointer;
  color: #b8bbc8;
  white-space: nowrap;
}

.customerMaintab li a {
  color: #b8bbc8;
}

.customerMaintab li.react-tabs__tab--selected:after {
  content: '';
  width: 40%;
  height: 5px;
  background: @theme-color;
  position: absolute;
  bottom: 0px;
  border-radius: 10px 10px 0 0;
  margin: auto;
  left: 0;
  right: 0;
}

.customerMaintab li.react-tabs__tab--selected {
  color: @theme-color;
  font-weight: 600;
}

.customerMaintab li.react-tabs__tab--selected a {
  color: @theme-color;
}

// test
.customerMaintab a {
  color: #b8bbc8;
  position: relative;
  text-decoration: none;
  list-style: none;
  padding: 15px 8px;
  position: relative;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1.5px;
  cursor: pointer;
  white-space: nowrap;
}

.customerMaintab a:hover {
  color: #b8bbc8;
}

.customerMaintab .react-tabs__tab--selected:after {
  content: '';
  width: 40%;
  height: 5px;
  background: @theme-color;
  position: absolute;
  bottom: 0px;
  border-radius: 10px 10px 0 0;
  margin: auto;
  left: 0;
  right: 0;
}

.customerMaintab .react-tabs__tab--selected {
  color: @theme-color;
  font-weight: 600;
}

.customerMaintab .react-tabs__tab--selected a {
  color: @theme-color;
}

.customerSubTab[data-tabs='true'] > ul,
.customerSubTab:not([data-tabs='true']) {
  border: 1px solid #efefef;
  padding: 10px 20px;
  background-color: #f8f8f8;
  display: flex;
  margin-bottom: 0;
  min-width: 100%;
}

.customerSubTab a {
  position: relative;
  list-style: none;
  padding: 0 20px 0 0;
  cursor: pointer;
}

.customerSubTab a,
.customerSubTab a:hover,
.customerSubTab a:focus {
  color: #545454;
}

.customerSubTab a.react-tabs__tab--selected {
  color: @theme-color;
}

.customerSubTab > ul > li {
  list-style: none;
  padding: 0 20px 0 0;
  cursor: pointer;
  white-space: nowrap;
}

.customerSubTab > ul > li.react-tabs__tab--selected {
  color: @theme-color;
}

.customerMaintab .headerInfoIcon {
  width: 12px;
  height: 12px;
  cursor: pointer;
  margin-right: 5px;
}

.leftSubTab {
  ul {
    padding: 0;
    li {
      list-style: none;
      font-size: 15px;
      color: @themeDarkBoreder;
      padding: 15px 0;
      cursor: pointer;
      &.react-tabs__tab.react-tabs__tab--selected {
        color: @theme-color;
        font-weight: 600;
      }
    }
  }
}

.rightArrow,
.leftArrow {
  width: 25px;
  height: 25px;
  // position: absolute;
  top: 0;
  margin: auto 0;
  bottom: 0;
  border: 1px solid #ddd;
  border-radius: 100%;
  text-align: center;
  line-height: 28px;
  cursor: pointer;
  background-color: #fff;
  z-index: 1;
  display: flex;
}

.rightArrow {
  // right: 0;
  margin-left: 5px;
}

.leftArrow {
  // left: -10px;
}

.rightArrow img,
.leftArrow img {
  width: 10px;
  margin: auto;
  height: 10px;
}

.subTabInnerDropdown {
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  position: fixed;
  left: auto;
  display: none;
  z-index: 100000;

  ul {
    padding: 0;
    margin: 0;
    li {
      list-style: none;
      padding: 10px;
      text-transform: capitalize;
      cursor: pointer;
      letter-spacing: 0.5px;
      white-space: nowrap;
      a {
        color: #292d34 !important;
        font-size: 14px;
        font-weight: 600;
      }
      &.active {
        background-color: @theme-color;
        border-radius: 5px;
        a {
          color: #fff !important;
        }
      }
    }
  }
}

.customerSubTab li,
.customerMaintab li {
  &:hover {
    .subTabInnerDropdown {
      display: block;
    }
  }
}

.customerSubTab a,
.customerMaintab a {
  &:hover {
    .subTabInnerDropdown {
      display: block;
    }
  }
}

@primary-color: #283895;@secondary-color: #9999ff;@text-color: #545454;@heading-color: #191c27;@nav-dark-bg: #283895;@nav-dark-text-color: #9DDAE9;@header-text-color: #191c27;@layout-header-background: #1b1010;@layout-footer-background: #fffffd;@body-background: #fafafa;@hor-nav-text-color: #fffffd;
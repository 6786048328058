@import 'variables.less';
.inventoryTab {
  height: 100vh;
}
.quickSearchTable {
  width: 100%;
  overflow: auto;
  height: 100%;
  table {
    width: 100%;
    tr {
      th {
        background-color: #f8f8f8;
        padding: 10px;
        border: 1px solid #e8e8e8;
        color: @theme-color;
        text-transform: uppercase;
        &:nth-child(3) {
          text-align: center;
        }
      }
      td {
        padding: 6px;
        border: 1px solid #e8e8e8;
        white-space: nowrap;
        min-width: 70px;
        &:hover {
          background-color: @themeLightColor;
          cursor: pointer;
        }
      }
      .quickSearchTitleTd {
        background-color: #f8f8f8;
        &:hover {
          background-color: #f8f8f8;
        }
      }
    }
  }
}
.quickSearchMainWrapper {
  display: flex;
  height: 100%;
  .quickSearcLeft {
    width: 360px;
    background: @themeLightColor;
    padding: 15px;
    position: relative;
    overflow: auto;
    // height: calc(~"100vh - 134px");
    min-height: 100%;
  }
  .quickSearchRight {
    width: calc(~'100% - 360px');
    padding: 10px;
    &.checkImageWrapper {
      padding: 0;
    }
  }
}
.pointer-events-none {
  pointer-events: none;
}
